export default {
  required: [
    'acceptIsRelatedToGastro',
  ],
  properties: {
    acceptIsRelatedToGastro: {
      type: 'boolean',
      enum: [
        true,
      ],
    },
  },
};
