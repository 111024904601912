export default {
  required: [
    'isMembershipReEntry',
  ],
  properties: {
    isMembershipReEntry: {
      type: 'boolean',
      enum: [
        true,
        false,
      ],
    },
  },
};
