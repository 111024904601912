import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import Vue from 'vue';

export default {
  allOf: [
    {
      ...queryBuilder.if({ isMemberContactReadonly: false }),
      then: {
        properties: {
          memberContact: {
            type: 'object',
            properties: {
              address: {
                ...queryBuilder.properties({
                  isCompany: { type: 'boolean', required: true },
                  companyLegalFormId: { type: ['number', 'string', 'null'] },
                  salutation: { type: 'number', required: true },
                  firstName: { type: 'string', required: true },
                  lastName: { type: 'string', required: true },
                  street: { type: 'string', required: true },
                  houseNumber: { type: 'string', required: true },
                  postalCode: { type: 'string', required: true },
                  city: { type: 'string', required: true },
                  eMail: { type: 'string', pattern: validationPatterns.email, required: true },
                  dateOfBirth: { type: ['string', 'null'], pattern: validationPatterns.date },
                  companyRegisterNr: { type: ['string', 'null'], pattern: validationPatterns.companyUid },
                }),
                allOf: [
                  {
                    ...queryBuilder.if({ isCompany: false }),
                    then: {
                      ...queryBuilder.properties({
                        dateOfBirth: {
                          required: true,
                          type: 'string',
                        },
                      }),
                    },
                  },
                  {
                    // If Einzelunternehmen
                    ...queryBuilder.if({ isCompany: true, companyLegalFormId: Vue.$config.values['gs-business-legal-form-einzelunternehmen-lookup-id'] }),
                    then: {
                      ...queryBuilder.properties({
                        isCompanyRegistered: { required: true },
                        companyName: { required: true },
                        dateOfBirth: {
                          required: true,
                          type: 'string',
                        },
                      }),
                    },
                  },
                  {
                    ...queryBuilder.if({ isCompany: true }),
                    ...queryBuilder.thenRequired([
                      'companyName',
                      'companyLegalFormId',
                      'isCompanyRegistered',
                    ]),
                  },
                  {
                    ...queryBuilder.if({ isCompanyRegistered: true }),
                    ...queryBuilder.thenRequired([
                      'companyRegisterNr',
                    ]),
                  }, {
                    ...queryBuilder.if({ isCompany: false }),
                    then: {
                      ...queryBuilder.properties({
                        dateOfBirth: {
                          required: true,
                          type: 'string',
                        },
                      }),
                    },
                  },
                  {
                    // If Einzelunternehmen
                    ...queryBuilder.if({ isCompany: true, companyLegalFormId: Vue.$config.values['gs-business-legal-form-einzelunternehmen-lookup-id'] }),
                    then: {
                      ...queryBuilder.properties({
                        dateOfBirth: {
                          required: true,
                          type: 'string',
                        },
                      }),
                    },
                  },
                  {
                    ...queryBuilder.if({ isCompany: true }),
                    ...queryBuilder.thenRequired([
                      'companyName',
                      'companyLegalFormId',
                      'isCompanyRegistered',
                    ]),
                  },
                  {
                    ...queryBuilder.if({ isCompanyRegistered: true }),
                    ...queryBuilder.thenRequired([
                      'companyRegisterNr',
                    ]),
                  },
                ],
              },

            },
          },
        },
      },
    },
  ],
};
