import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  type: 'object',
  allOf: [
    {
      ...queryBuilder.if({ isMembershipBusinessReadonly: false }),
      then: {
        properties: {
          membershipBusiness: {
            ...queryBuilder.properties({
              companyName: { type: 'string', required: true },
              startDate: { type: 'string', required: true },
              isTeachingApprentices: { type: 'boolean', required: true },
              isSeasonal: { type: 'boolean', required: true },
              accommodation: {
                type: 'object',
                properties: {
                  isAccommodation: { type: 'boolean', required: true },
                },
              },
            }),
          },
        },
      },
    },
    {
      ...queryBuilder.if({ isMemberBusinessContactReadonly: false }),
      then: {
        properties: {
          memberBusinessContact: {
            type: 'object',
            properties: {},
            allOf: [{
              ...queryBuilder.if({ isDifferentFromOwner: true }),
              then: {
                properties: {
                  address: {
                    ...queryBuilder.properties({
                      street: { type: 'string', required: true },
                      houseNumber: { type: ['string', 'null'] },
                      postalCode: { type: 'string', required: true },
                      city: { type: 'string', required: true },
                      eMail: { type: ['string', 'null'], pattern: validationPatterns.email },
                    }),
                  },
                },
              },
            }],
          },
        },
      },
    },
  ],
} as IJSONSchema;
