import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {

  properties: {

    membershipBusiness: {
      type: 'object',
      // TODO: Use this simpler version once glittr.core is updated
      // ...queryBuilder.if({ 'accommodation.isAccommodation': true }),
      properties: {
        accommodation: {
          type: 'object',
          accommodationTypeId: { type: 'string', required: true },
          isClassified: { type: 'boolean', required: true },
          allOf: [
            {
              ...queryBuilder.if({ isAccommodationClassified: true }),
              ...queryBuilder.thenRequired([
                'classificationStarsCount',
                'countOfRooms',
                'countOfBeds',
              ]),
            },
          ],
        },
      },
    },
  },
} as IJSONSchema;
