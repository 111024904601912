










import Vue from 'vue';
import { StepData } from '@/src/components/processes/rs-wizard.vue';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';
import oneSchema from './schema/1.schema';
import twoSchema from './schema/2.schema';
import threeSchema from './schema/3.schema';
import fourSchema from './schema/4.schema';
import fiveSchema from './schema/5.schema';
import sixSchema from './schema/6.schema';
import sevenSchema from './schema/7.schema';
import eightSchema from './schema/8.schema';
import nineSchema from './schema/9.schema';
import tenSchema from './schema/10.schema';
import elevenSchema from './schema/11.schema';
import twelveSchema from './schema/12.schema';
import thirteenSchema from './schema/13.schema';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    model: CreateMembershipRequestViewModel,
    title: 'page.createMembership.page.title',
    steps: [
      {
        breadcrumb: 'page.createMembership.label.stepTitles.1',
        component: () => import('./createMembership1.vue'),
        schema: oneSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.2',
        component: () => import('./createMembership2.vue'),
        schema: twoSchema,
      },
      {
        title: 'page.createMembership.title.membershipCalculation',
        breadcrumb: 'page.createMembership.label.stepTitles.3',
        component: () => import('./createMembership3.vue'),
        schema: threeSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.4',
        component: () => import('./createMembership4.vue'),
        schema: fourSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.5',
        component: () => import('./createMembership5.vue'),
        schema: fiveSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.6',
        component: () => import('./createMembership6.vue'),
        schema: sixSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.7',
        component: () => import('./createMembership7.vue'),
        condition: (data: CreateMembershipRequestViewModel) => data.membershipBusiness?.accommodation?.isAccommodation === false,
        schema: sevenSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.8',
        component: () => import('./createMembership8.vue'),
        condition: (data: CreateMembershipRequestViewModel) => data.membershipBusiness?.accommodation?.isAccommodation === true,
        schema: eightSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.9',
        component: () => import('./createMembership9.vue'),
        schema: nineSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.10',
        component: () => import('./createMembership10.vue'),
        schema: tenSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.11',
        component: () => import('./createMembership11.vue'),
        sendData: (data: CreateMembershipRequestViewModel) => !data.orderBasket?.isOnlinePayment,
        schema: elevenSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.12',
        component: () => import('./createMembership12.vue'),
        condition: (data: CreateMembershipRequestViewModel) => data.orderBasket?.isOnlinePayment,
        sendData: true,
        schema: twelveSchema,
      },
      {
        breadcrumb: 'page.createMembership.label.stepTitles.13',
        component: () => import('./createMembership13.vue'),
        disablePreviousSteps: true,
        clearProgressOnMounted: true,
        schema: thirteenSchema,
      },
    ] as StepData[],
  }),
  methods: {
    async initializeData() {
      const intialRequest = await this.$service.api.memberships.getMyInitialMembershipRequest();
      const initializedModel = new CreateMembershipRequestViewModel().fromModel(intialRequest);
      return initializedModel;
    },
    async serviceFunction(model: CreateMembershipRequestViewModel) {
      await this.$service.api.memberships.createMembership(model);
      try {
        await this.$auth.refresh();
      } catch (error) {
        console.error('Unable to refresh authentication');
        console.error(error);
      }
    },
  },
});
