import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {
  ...queryBuilder.properties({
    socialInsuranceId: { type: 'string', required: true },
    entryDate: { type: 'string', required: true },
  }),
  allOf: [
    {
      ...queryBuilder.if({ additionalInformationRequired: true }),
      ...queryBuilder.thenRequired([
        // 'hasSocialInsurance',
        'hasOperatingLicence',
        'hasCertificateOfProficiency',
      ]),
    },
    {
      ...queryBuilder.if({ hasOperatingLicence: true }),
      ...queryBuilder.thenRequired([
        'dateOperatingLicenceIssued',
        'operatingLicenceOwner',
      ]),
    },
    {
      ...queryBuilder.if({ hasCertificateOfProficiency: true }),
      ...queryBuilder.thenRequired([
        'dateCertificateOfProficiencyIssued',
        'certificateOfProficiencyCantonId',
      ]),
    },
    {
      ...queryBuilder.if({ additionalInformationRequired: true }),
      then: {
        ...queryBuilder.if({ hasCertificateOfProficiency: false }),
        ...queryBuilder.thenRequired([
          'certificateOfProficiencyCourse',
        ]),
      },
    },
  ],
} as IJSONSchema;
