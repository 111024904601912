import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  properties: {
    memberCorrespondenceContact: {
      ...queryBuilder.properties({
        isDifferentFromOwner: { type: 'boolean', required: true },
      }),
      ...queryBuilder.if({ isDifferentFromOwner: true }),
      then: {
        ...queryBuilder.properties({
          isTrusteeAddress: { type: 'boolean', required: true },
          address: {
            type: 'object',
            properties: {
              companyName: { type: ['string', 'null'] },
              salutation: { type: 'number', required: true },
              firstName: { type: 'string', required: true },
              lastName: { type: 'string', required: true },
              street: { type: 'string', required: true },
              houseNumber: { type: 'string', required: true },
              postalCode: { type: 'string', required: true },
              city: { type: 'string', required: true },
              eMail: { type: 'string', pattern: validationPatterns.email, required: true },
              // dateOfBirth: { type: 'string', pattern: validationPatterns.date, required: true },
            },
          },
        }),
      },
    },
  },
};
