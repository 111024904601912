import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {

  properties: {

    membershipBusiness: {
      type: 'object',
      // TODO: Use this simpler version once glittr.core is updated
      // ...queryBuilder.if({ 'accommodation.isAccommodation': true }),
      properties: {
        gastronomy: {
          ...queryBuilder.properties({
            businessTypeId: { type: 'string', required: true },
            placesInHouse: { type: 'integer' },
            placesHall: { type: 'integer' },
            placesTerrace: { type: 'integer' },
            isWheelchairAccessible: { type: 'boolean', required: true },
            isRestRoomHandicappedAccessible: { type: 'boolean', required: true },
            hasSmokingRoom: { type: 'boolean', required: true },
          }),
        },
      },
    },
  },
} as IJSONSchema;
